<template>
    <div 
        class="header" 
        :class="{ 'lp-centralized': isLPCentralized }"
    >
        <div class="header-inner">
            <div class="header-left">
                <InfluHeader
                    class="influ-header"
                    v-if="Object.keys(getInfluData).length && isLPCentralized"
                    :ecommData="getInfluData"
                    :ecommCustomization="ecommCustomization"
                    :isLPCentralized="isLPCentralized"
                />
                <q-img 
                    v-else
                    :src="companyDetails?.logo" class="logo" 
                />
            </div>
            <div class="header-center">
                <q-form @submit.prevent="menuSearchResults.open()">
                    <q-input
                        ref="refSearchInput" 
                        outlined 
                        autocomplete="off"
                        @click="menuSearchResults.open()"
                        placeholder="O que você procura hoje?"
                        menu-anchor="none"
                        v-model="searchQuery"
                    >
                        <q-btn type="submit" flat>
                            <q-icon name="search" />
                        </q-btn>
                    </q-input>
                </q-form>
                <MenuSearchResults
                    v-if="showSearchResults"
                    :getAllProducts="getAllProducts" 
                    :searchQuery="searchQuery"
                    :closeSearchResults="menuSearchResults.close"
                    :position="searchResultsPosition"
                    :isLPCentralized="isLPCentralized"
                />
            </div>
            <div 
                class="header-right" 
            >
                <div v-if="!isLPCentralized">
                    <q-btn 
                        v-if="$q.screen.width > 599" @click="goToExternalStore"
                        flat class="external-link" 
                    >
                        Ir para {{ companyDetails?.name.split(',').slice(0, 2).join() }}
                    </q-btn>

                    <div v-else>
                        <q-btn flat color="black" icon="menu" @click="setSidebarStatus(true)" />
                    </div>
                </div>
                <div v-else>
                    <q-btn
                        flat
                        class="q-mt-xs full-width btn-share"
                        @click="shareStore"
                    >
                        <q-tooltip v-if="$q.screen.gt.sm">
                        Compartilhar loja
                        </q-tooltip>
                        <q-icon name="share"/>
                    </q-btn>
                </div>
            </div>
        </div>
    </div>
    
    <Tabs 
        v-if="canShowTabs" 
        :data="getProductsByCategories" 
        :scrollTo="scrollTo"
    />

    <Sidebar 
        :data="getProductsByCategories" 
        :scrollTo="scrollTo" 
        :handleMenuClick="handleMenuClick"
    />
</template>
<script setup>
import { computed, defineProps, nextTick, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useRoute } from "vue-router";
import { useQuasar } from 'quasar'; 
 import { checkIfNativeSharingIsAvailable, nativeSharing } from "@/shared/helpers/share"

import Sidebar from './Sidebar.vue';
import Tabs from './Tabs.vue';
import MenuSearchResults from './MenuSearchResults.vue';
import InfluHeader from "./InfluHeader.vue";

const props = defineProps({
    companyDetails: Object,
    getProductsByCategories: Object,
    scrollTo: Function,
    ecommCustomization: Object,
    isLPCentralized: {
        required: true,
        type: Boolean,
        default: false
    }
})

const refSearchInput = ref(null)
const searchQuery = ref('')
const showSearchResults = ref(false);

// Posição dinamica do Menu de produtos pesquisados, de acordo com a posição do q-input
const searchResultsPosition = reactive({}) 


const $q = useQuasar();
const route = useRoute();

const { getAllProducts, isLPCentralized, getInfluData } = useGetters({
    getAllProducts: 'public_landingpage/getAllProducts',
    isLPCentralized: "public_landingpage/isLPCentralized",
    getInfluData: "public_landingpage/getInfluData",
})

const { setSidebarStatus } = useActions({
    setSidebarStatus: 'public_landingpage/setSidebarStatus'
})


/**
 *  Exibe AS ABAS se a largura da tela for maior que 900px ,
 * houver produtos por categorias disponíveis,
 * e a página não estiver na LP centralizada.
* */
const canShowTabs = computed(() => 
    $q.screen.width > 900 && 
    Object.entries(props.getProductsByCategories).length &&
    !isLPCentralized.value
)


/* =============== METHODS =============== */

const handleMenuClick = (title) => {
 setSidebarStatus(false);
 menuSearchResults.close()

  nextTick(() => {
    props.scrollTo(title);
  });
};

const shareStore = async () => {
    if ( checkIfNativeSharingIsAvailable ) {
        try {
            await nativeSharing({
                title: props.ecommCustomization?.ecomm_customization?.highlight_text,
                text: location.href
            })
        } catch (error) {
            console.log('Houve um erro ao compartilhar')
        }
    }
}

/* Vai para a o site do parceiro */
const goToExternalStore = () => {
    window.open(
        props.ecommCustomization?.ecomm_customization?.link_to_store,
        '_blank'
    )
}

const menuSearchResults = {
    open() {
        showSearchResults.value = true;
        bodyScroll.enabled();


        const inputElement = refSearchInput.value.$el.getBoundingClientRect();

        // Define as coordenadas do menu
        searchResultsPosition.top = `${inputElement.bottom + window.scrollY}px`;
        searchResultsPosition.left = `${inputElement.left + window.scrollX}px`;
        searchResultsPosition.width = `${inputElement.width}px`;
    },

    close() {
        showSearchResults.value = false;
        bodyScroll.disabled();
    }
}

/* Funções para desabilitar e habilitar o scroll do body */
const bodyScroll = {
    enabled: () => {
        document.body.classList.add('no-scroll')
    },

    disabled: () => {
        document.body.classList.remove('no-scroll')
    }
}

/* Caso o menu de produtos pesquisados esteja aberto, detecta quando 'ESC' é pressionado para fecha-lo */
const handleEscapeKey = (event) => {
    if ( showSearchResults.value ) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            menuSearchResults.close()
        }
    }
}

/* Define valores personalizados para as variaveis, quando o parceiro é FLIPNET */
const setCSSVariables = () => {
    if ( route.params.childSlug === "flipnet" ) {
        const el = document.documentElement;

        el.style.setProperty('--width__logo-desktop', '75px');
        el.style.setProperty('--top__menu-lp-searchbar', '72px');
    }
}

/* =============== METHODS =============== */



onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey)
    setCSSVariables()
})

onUnmounted(() => document.removeEventListener('keydown', handleEscapeKey))

</script>
<style scoped>
.header {
    padding: 0.7rem 0.6rem;
    background-color: #fff;
}

/* INICIO: Estilos aplicados apenas na LP CENTRALIZADA */

.header.lp-centralized .header-inner {
 display: grid;
 justify-content: normal;
}

.header.lp-centralized .header-left {
    order: 2;
}

.header.lp-centralized .header-center {
    order: 1;
}

.header.lp-centralized .header-right {
    display: none;
}


/* FIM: Estilos aplicados apenas na LP CENTRALIZADA */

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: center;
}

.header-right .external-link {
    border-radius: 6px;
    transition: background-color 250ms ease;
    text-decoration: underline;
}

.header-right .external-link:hover {
    background-color: #f4f4f4;
}

.header-right > div .q-btn {
    padding: 0px 0.1rem;
}

.header-right .btn-share .q-icon {
    font-size: 1.35rem;
}

.q-form {
    width: 100%;
}

.header.lp-centralized .q-form {
    max-width: 100%;
}

.q-form :deep(i) {
    color: var(--primary-custom-color)
}

.q-form :deep(.q-field__control) {
    height: 45px;
}

:deep(.q-field--outlined .q-field__control) {
    height: 55px
}

:deep(.q-field--outlined .q-field__control) {
    background-color: #f4f4f4;
}

:deep(.q-field--outlined .q-field__control:before) {
    border: none;
}

.logo {
    width: var(--width__logo-mobile);
}

.external-link :deep(span) {
    text-transform: initial;
    font-size: clamp(12px, 3.5vw, 15px);
}

.external-link.text-primary {
    color: var(--primary-custom-color) !important;
}

@media ( max-width: 767px ) {
    :deep(.q-field .q-btn) {
        padding-right: 0px;
    }

    :deep(.btn-add-to-cart) {
        max-width: 42px;
        max-height: 25px;
    }

    :deep(.btn-add-to-cart .q-btn__content) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :deep(.btn-add-to-cart .q-icon) {
        font-size: 1.2rem;
    }
}

@media ( min-width: 768px ) {
    .header {
        padding: 1rem;
    }

    .header-right {
        display: block;
    }

    .logo {
        width: var(--width__logo-desktop);
    }

    .q-form {
        max-width: 700px;
    }

    .header.lp-centralized .header-inner {
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .header.lp-centralized .header-left {
        order: 1;
        max-width: 300px;
    }

    .header.lp-centralized .header-center {
        order: 2;
    }
}
</style>
