<template>
    <q-dialog
        v-model="showSidebar"
        position="right"
        :maximized="true"
        style="margin: 0; padding: 0"
        @hide="setSidebarStatus(false)"
    >
    <q-card class="full-height" style="width: 220px">
      <q-list bordered separator>
        <h4>Navegue por categoria</h4>
        <q-item
          :key="i"
          clickable
          v-ripple
          v-for="(menu, i) in data"
          @click="handleMenuClick(menu.title)"
        >
          <q-item-section class="text-uppercase"
            ><b>{{ menu.title }}</b></q-item-section
          >
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
import { useGetters, useActions } from "vuex-composition-helpers";

defineProps({
  data: {
    type: Array,
    required: true,
  },
  handleMenuClick: Function,
});

const showSidebar = ref(false)

const { setSidebarStatus } = useActions({
    setSidebarStatus: 'public_landingpage/setSidebarStatus'
})

const { getSidebarStatus } = useGetters({
    getSidebarStatus: 'public_landingpage/getSidebarStatus'
})

watch(() => getSidebarStatus.value, (newValue) => {
    showSidebar.value = newValue
})
</script>
<style scoped>
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0.8rem 0px 0.5rem 0.8rem;
  }

  :deep(.q-list--separator > .q-item-type + .q-item-type) {
    border-top: 1px solid #e8e8e8;
  }

  :deep(.q-item__section b) {
    font-weight: 400;
    font-size: 13px;
  }
</style>