<template>
  <div 
    v-if="!in_request" 
    class="public-store"
    :class="{ 'lp-centralized': isLPCentralized }"
  >
    <div class="container" v-if="Object.keys(getCompanieDetails).length">
      <Header 
        :companyDetails="getCompanieDetails" 
        :getProductsByCategories="getProducts"
        :ecommCustomization="getInfluEcomCustomization"
        :scrollTo="scrollTo"
        :isLPCentralized="isLPCentralized"
      />
    </div>

    <InfluHeader
      v-if="Object.keys(getInfluData).length && !isLPCentralized"
      class="influ-header"
      :ecommData="getInfluData"
      :ecommCustomization="getInfluEcomCustomization"
      :isLPCentralized="isLPCentralized"
    />
    

    <!--- Exibir apenas na LP Centralizada  -->
    <Companies 
      v-if="isLPCentralized"
      :companiesData="getOnlyCompaniesWithProductsLPCentralized" 
      :scrollTo="scrollTo"
    />


    <template v-if="Object.keys(getProducts).length">
      <div>
        <ProductsInCentralized 
          v-if="isLPCentralized"
          :productsByCompany="getProducts" 
        />
        <ProductsInDefault 
          v-else
          :products="getProducts" 
        />
      </div>
    </template>
    <template v-else>
      <div
        class="q-pa-xl text-center text-h5 flex flex-center"
        style="height: 490px"
      >
        Não foram encontrados itens favoritos.
      </div>
    </template>

    <Footer
      :influ="getInfluData"
      :company="getCompanieDetails"
      :prodsByCat="getProducts"
      v-if="
        Object.keys(getInfluData).length &&
        Object.keys(getCompanieDetails).length
      "
    />
  </div>
</template>

<script setup>
import {
  setCustomLayoutLP,
  getCustomLayoutLP,
} from "@/shared/helpers/customLayoutHelper";
// import CarouselMultiItensComponent from "./components/Carousel.vue";
import InfluHeader from "./components/InfluHeader.vue";
import { baseURL } from "@/shared/http/api";
import axios from "axios";

import { useActions, useGetters } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import ProductsInCentralized from "./components/lp-centralized/Products.vue";
import ProductsInDefault from "./components/lp-default/Products.vue";
import Companies from "./components/lp-centralized/Companies.vue";

const route = useRoute();
const in_request = ref(true);

const { childSlug, parentSlug } = route.params

const {
  getProducts, getInfluData, getCompanieDetails,
  getInfluEcomCustomization, isLPCentralized,
  getOnlyCompaniesWithProductsLPCentralized
} = useGetters({
  getProducts: "public_landingpage/getProducts",
  getInfluData: "public_landingpage/getInfluData",
  getCompanieDetails: "public_landingpage/getCompanieDetails",
  getInfluEcomCustomization: "public_landingpage/getInfluEcomCustomization",
  isLPCentralized: "public_landingpage/isLPCentralized",
  getOnlyCompaniesWithProductsLPCentralized: "public_landingpage/getOnlyCompaniesWithProductsLPCentralized"
});

const {
  fetchInfluProducts, fetchInfluEcomCustomization,
  fetchCompanieDetails, setLPCentralized,
  fetchLinksAndVouchers
} = useActions({
  fetchInfluProducts: "public_landingpage/fetchInfluProducts",
  fetchInfluEcomCustomization: "public_landingpage/fetchInfluEcomCustomization",
  fetchCompanieDetails: "public_landingpage/fetchCompanieDetails",
  setLPCentralized: "public_landingpage/setLPCentralized",
  fetchLinksAndVouchers: "public_landingpage/fetchLinksAndVouchers"
});

const scrollTo = (title) => {
  document.getElementById(title).scrollIntoView({ behavior: "smooth" });
};

const layout = ref(getCustomLayoutLP("_LP"));

const fetchParam = (param) =>
  axios.get(
    `${baseURL}/api/v1/parameters/p/${childSlug}/${param}`
  );


if ( childSlug === "flipnet" ) setLPCentralized(true)
else setLPCentralized(false)


onMounted(() => {
  const payload = {
    slug: childSlug,
    parentSlug: parentSlug,
  };

  
  const promises = [
    fetchParam("custom_layout")
      .then(({ data }) => {
        setCustomLayoutLP(data.value);

        if ( childSlug === "flipnet" ) {
          return fetchLinksAndVouchers({ parentSlug: parentSlug })
        }

        return Promise.resolve()
      })
      .then(() => (layout.value = getCustomLayoutLP("_LP"))),
    fetchInfluProducts(payload),
    fetchInfluEcomCustomization(payload),
    fetchCompanieDetails({ slug: childSlug }),
  ]


  Promise.all(promises)
  .finally(() => (in_request.value = false));
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.public-store.lp-centralized {
  background-color: #ebebeb;
}

.container {
  padding: 0px;
  font-family: "Montserrat", sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
</style>
