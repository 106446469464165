<template>
  <div class="container-categorys">
    <div v-for="({ title, prods_parent }, index ) in products" :key="index">
      <div 
        class="text-black text-weight-thin text-center category-title"
        v-text="title" 
        :id="title"
      />
      <div class="products">
        <Product 
          v-for="(product, productIndex) in prods_parent" 
          :key="productIndex" 
          class="product" 
          :data="product" 
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import Product from '../Product.vue'

defineProps({
  products: Object,
})
</script>
<style scoped>
.container-categorys {
  margin-top: 2.5rem;
  max-width: 1280px;
}

.container-categorys > div {
  margin-bottom: 2rem;
}

.products {
  display: grid;
  gap: 1rem;
  justify-content: center;
  overflow-x: hidden;
  padding: 0.1rem;
  scrollbar-width: none;
  margin: 2rem 14px 0px 14px;
}

.category-title {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: clamp(22px, 4vw, 32px);
}


.category-title::before,
.category-title::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dddcdc;
  margin: 0 15px;
}

@media (max-width: 639px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 640px) {
  .container-categorys {
    margin: 2.5rem auto;
  }

  .products {
    padding: 0.5rem 1rem;
    grid-template-columns: repeat(5, minmax(180px, auto));
    gap: 2rem;
  }

  .category-title {
    padding: 16px 16px;
  }
}
</style>
