<template>
    <div 
    ref="refMenuSearchbar" 
    class="q-menu-lp-searchbar"
    :class="{ 'lp-centralized': isLPCentralized }"
    :style="position"
    >
        <div class="searchbar-content">
            <q-btn flat icon="close" @click="closeSearchResults()"/>
            
            <div class="searchbar-products" v-if="filteredProducts.length && searchQuery.length">
                <div v-for="product in filteredProducts" :key="product.product_id">
                    <Product :data="product" />
                </div>
            </div>

            <div style="margin: 0 auto" v-else-if="searchQuery.length === 0">
                Procure por algo...
            </div>

            <div style="margin: 0 auto" v-if="filteredProducts.length === 0 && searchQuery.length">
                <span>Nenhum produto encontrado...</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps } from 'vue';
import Product from './Product.vue';

const props = defineProps({
    getAllProducts: Object,
    searchQuery: String,
    closeSearchResults: Function,
    isLPCentralized: {
        type: Boolean,
        required: true
    },
    position: {
        type: Object,
        required: true,
    }
})

// Computed que filtra os produtos com base no texto do q-input
const filteredProducts = computed(() => {
  return props.getAllProducts.filter(product => 
    product.name.toLowerCase().includes(props.searchQuery.toLowerCase())
  );
});

</script>
<style scoped>
.q-menu-lp-searchbar {
    left: 0px;
    height: 100vh;
    padding-bottom: 5rem;
    position: fixed !important;
    box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
    background: #fff;
    border-radius: 4px;
    z-index: 6000;
    overflow: auto;
}

.searchbar-content {
    padding: 1rem;
    display: grid;
    position: relative;
    scrollbar-width: none;
}

.searchbar-content .q-btn:first-child {
    float: right;
    width: fit-content;
    position: absolute;
    right: 0px;
    top: 0.2rem
}

.searchbar-products {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr); /* 2 produtos por linha no mobile */
    justify-items: center;
    margin-top: 2rem;
    gap: 1rem; /* Espaçamento entre os produtos */
}

.searchbar-products .q-card {
    max-width: 100% ;
    min-width: 100%;
    width: 100% !important;
    height: 260px !important;
}

.searchbar-products .q-card :deep(.q-img) {
    height: 120px !important;
    max-width: 120px;
    margin: 0 auto;
}

.searchbar-products .q-card :deep(.card-header) {
    max-height: 120px;
}

.searchbar-products .q-card :deep(.text-caption) {
    font-size: clamp(7px, 3vw, 8px) !important;
}

.searchbar-products .q-card :deep(.card-title) {
    font-size: clamp(10px, 3.3vw, 12px);
}

.searchbar-products .q-card :deep(.discount) {
    font-size: clamp(9px, 3.3vw, 10px) !important;
}

.searchbar-products .q-card :deep(.current-price) {
    font-size: clamp(11px, 3.5vw, 13px);
}

.searchbar-products .q-card :deep(.hover-button) {
    display: none;
}

.searchbar-products .q-card :deep(.btn-add-to-cart) {
    display: none
}

.searchbar-products .q-card :deep(.description) {
    display: none;
}

@media ( max-width: 767px ) {
    .q-menu-lp-searchbar:not(.lp-centralized) {
        width: 100vw !important;
        left: 0px !important;
    }    
}

@media ( min-width: 768px ) {
    .searchbar-products {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Responsivo */
    }

    .searchbar-products .q-card {
        max-width: 200px !important;
        min-width: 200px !important;
        width: 200px !important;
        height: 260px !important;
    }
}
</style>